import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

const variants = {
  GridInternal: {
    singleColumn: {
      true: 'singleColumn [&.singleColumn]:desktop:grid-cols-1',
      false: '',
    },
  },
} as const;

export const GridInternal = ({ children, singleColumn, ...rest }: ComponentPropsWithoutRef<'div'> & { singleColumn?: boolean }) => {
  return (
    <div
      className={classNames(
        'mt-4 grid grid-cols-1 grid-rows-1 gap-0 desktop:grid-cols-[minmax(0,1fr)_336px] desktop:gap-8',
        singleColumn != undefined && variants.GridInternal.singleColumn[singleColumn.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};

export default { GridInternal };
