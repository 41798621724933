import Chip from '@/components/Layout/Chip';
import { useState } from 'react';
import PinnedMap from '../PinnedMap';
import ContactForm from '../ContactForm';
import Icon from '@/components/Layout/Icon';

export default function ContactBox() {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  return (
    <>
      <section className="flex flex-col gap-8 bg-mglBlue400 tablet:flex-row tablet:gap-16">
        <div className="flex w-full flex-col gap-6 text-mglGray50">
          <h2>Fale com a gente</h2>
          <p>Você tem algo a nos dizer? Clique no botão abaixo e preencha o formulário.</p>
          <p>Entraremos em contato assim que possível.</p>
          <div>
            <p style={{ marginBottom: '8px' }}>E-mail para sugestões de pauta:</p>
            <p className="flex items-center gap-2">
              <Icon icon="mdi:email-outline" /> redacao@canaltech.com.br
            </p>
          </div>
          <div>
            <hr style={{ marginBottom: '8px' }} />
            <em style={{ fontSize: '0.875rem' }}>
              Infelizmente, nem sempre conseguimos atender a todas as solicitações, mas nossa equipe está sempre a postos para ajudar.
            </em>
          </div>
          <Chip
            chipSize="medium"
            texto="formulário de contato"
            posIcon="chevron-right"
            borderColor="white"
            title="Abrir formulário de contato"
            color="white"
            hover="black"
            onClick={() => setIsFormModalOpen(true)}
          />
        </div>
        <PinnedMap />
      </section>
      <ContactForm isOpen={isFormModalOpen} setIsOpen={setIsFormModalOpen} />
    </>
  );
}
