import Script from 'next/script';

export default function PinnedMap() {
  return (
    <>
      <div id="map-wrap" className="w-full [&_div]:h-full [&_div]:min-h-[300px] [&_div]:w-full">
        <div id="map" />
      </div>
      <Script async defer type="text/javascript">
        {`function initMap() {
          var myLatLng = {lat: -23.6584903, lng: -46.5703268};

            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 17,
                center: myLatLng,
                scrollwheel: false
            });

            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                title: 'Canaltech'
            });
        }`}
      </Script>
      <Script async defer strategy="lazyOnload" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBqfn0mk2d5gLSHVW381bHbUtlkdC5nuoY&callback=initMap" />
    </>
  );
}
