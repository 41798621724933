import { useRouter } from 'next/router';
import HTMLReactParser from 'html-react-parser';
import { useState, useEffect } from 'react';
import { GridInternal } from '@/components/Internals/NewsItem/styles';
import HeaderImage from '@/components/Internals/HeaderImage';
import Chip from '@/components/Layout/Chip';
import ImageComponent from '@/components/Layout/Image';
import WrapperContent from '@/components/Layout/WrapperContent';
import { Stats } from '@/pages/anuncie/index';
import dynamic from 'next/dynamic';
import useBreakpoint from '@/hooks/useBreakpoint';
import * as S from './styles';
import { mglBlue } from '@/styles/designSystemColors';
import Icon from '@/components/Layout/Icon';

const FormModal = dynamic(() => import('./FormModal'));
const MidiaKit = dynamic(() => import('./MidiaKit'));

const icons = {
  atividade: {
    'Pageviews/mês': 'mdi:file-document-outline',
    'Visitas Totais/mês': 'mdi:eye',
    'Usuário Únicos/mês': 'mdi:person-outline',
    Inscritos: 'mdi:thumb-up',
  },
  publicoAlvo: {
    'Público Alvo': 'mdi:radio-tower',
    Masculino: 'mdi:gender-male',
    Feminino: 'mdi:gender-female',
  },
  redesSociais: {
    'Inscritos Facebook': 'mdi:facebook',
    'Inscritos Tik Tok': 'akar-icons:tiktok-fill',
    'Inscritos Instagram': 'mdi:instagram',
    'Inscritos Twitter': 'mdi:twitter',
    'Inscritos Linkedin': 'mdi:linkedin',
  },
} as const;

interface Activity extends Stats {
  label: keyof typeof icons.atividade;
}
interface Audience extends Stats {
  label: keyof typeof icons.publicoAlvo;
}

interface SocialNetwork extends Stats {
  label: keyof typeof icons.redesSociais;
}

export interface AdvertiseHereProps {
  text: string;
  headerImg: { url: string; srcset: { [key: number]: string }; blurhash: string };
  estatisticas: { atividade: Activity[]; publicoAlvo: Audience[]; redesSociais: SocialNetwork[] };
  midiaKit: {
    imagem: string;
    imagemAlt: string;
    titulo: string;
    descricao: string;
  };
  parceiros: {
    url: string;
    blurhash: string;
  }[];
}

export default function AdvertiseHere({ text, headerImg, estatisticas, midiaKit, parceiros }: AdvertiseHereProps) {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [utmCampaign, setUtmCampaign] = useState<string | string[]>('');
  const router = useRouter();

  const [isMobile] = useBreakpoint('phone');

  useEffect(() => {
    if (router?.query.utm_campaign) setUtmCampaign(router?.query.utm_campaign);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WrapperContent isOnHeader>
        <GridInternal>
          <S.AdvertiseHereMainContainer>
            <S.AdvertiseHerePageTextContainer>
              <HeaderImage url={headerImg.url} caption="" srcset={headerImg.srcset} isHeader="internal" />
              {isMobile && <MidiaKit midiaKit={midiaKit} setIsOpen={setIsFormModalOpen} />}
              {HTMLReactParser(text)}
              <S.AdvertiseHereChipContainer
                className="mb-16 hover:[&>div]:border-mglGray900 hover:[&>div]:bg-mglGray900 hover:[&>div]:text-mglGray50"
                onClick={() => setIsFormModalOpen(true)}>
                <Chip
                  title="deixar a sua mensagem"
                  texto="deixar a sua mensagem"
                  chipSize="large"
                  borderColor="black"
                  color="black"
                  backgroundColor="white"
                  posIcon="chevron-right"
                />
              </S.AdvertiseHereChipContainer>
            </S.AdvertiseHerePageTextContainer>
            <h2 className="mb-12 text-2xl font-medium text-mglGray900">Engajamento</h2>
            <section className="flex flex-col gap-2 bg-mglGray50 pb-12 pt-8 desktop:pt-[initial]">
              <div className="grid grid-cols-2 gap-3 desktop:grid-cols-4 [&>div]:[@media_only_screen_and_(max-width:426px)]:[grid-column:span_2]">
                {estatisticas.atividade.map(activity => (
                  <S.AdvertiseHereGridItem key={activity.label}>
                    <Icon icon={icons.atividade[activity.label]} color={mglBlue.mglBlue400} />
                    <S.AdvertiseHereMainText>{activity.quantidade}</S.AdvertiseHereMainText>
                    <S.AdvertiseHereSubText>{activity.label}</S.AdvertiseHereSubText>
                  </S.AdvertiseHereGridItem>
                ))}
              </div>

              <div className="[&>div]:bg-gray-50 grid grid-cols-2 gap-4 shadow-[0px_10px_35px_-10px_rgba(64,_59,_75,_0.1)] phone:gap-3 desktop:grid-cols-3 [&>div]:[@media_only_screen_and_(max-width:767px)]:[grid-column:span_2] first:[&>div]:[@media_only_screen_and_(max-width:992px)]:[grid-column:span_2]">
                {estatisticas.publicoAlvo.map(audience => (
                  <S.AdvertiseHereGridItem key={audience.label}>
                    <Icon icon={icons.publicoAlvo[audience.label]} color={mglBlue.mglBlue400} />
                    <S.AdvertiseHereMainText>{audience.quantidade}</S.AdvertiseHereMainText>
                    <S.AdvertiseHereSubText>{audience.label}</S.AdvertiseHereSubText>
                  </S.AdvertiseHereGridItem>
                ))}
              </div>

              <div className="grid grid-cols-2 gap-3 desktop:grid-cols-5 [&>div]:[@media_only_screen_and_(max-width:426px)]:[grid-column:span_2] first:[&>div]:[@media_only_screen_and_(max-width:992px)]:[grid-column:span_2]">
                {estatisticas.redesSociais.map(socialNetwork => (
                  <S.AdvertiseHereGridItem key={socialNetwork.label}>
                    <Icon icon={icons.redesSociais[socialNetwork.label]} color={mglBlue.mglBlue400} />
                    <S.AdvertiseHereMainText>{socialNetwork.quantidade}</S.AdvertiseHereMainText>
                    <S.AdvertiseHereSubText>{socialNetwork.label}</S.AdvertiseHereSubText>
                  </S.AdvertiseHereGridItem>
                ))}
              </div>
            </section>

            <section className="px-0 py-12">
              <h2 className="mb-12 text-2xl font-medium text-mglGray900">Confira alguns de nossos anunciantes e parceiros</h2>
              <div className="grid grid-cols-2 gap-3 desktop:grid-cols-4 [@media_only_screen_and_(max-width:426px)]:[&>div]:[grid-column:span_2]">
                {parceiros.map(partner => (
                  <div className="rounded border border-solid border-mglGray200" key={partner.url}>
                    <ImageComponent src={partner.url} blurhash={partner.blurhash} />
                  </div>
                ))}
              </div>
            </section>
          </S.AdvertiseHereMainContainer>
          {!isMobile && <MidiaKit midiaKit={midiaKit} setIsOpen={setIsFormModalOpen} />}
        </GridInternal>
      </WrapperContent>

      <FormModal isOpen={isFormModalOpen} setIsOpen={setIsFormModalOpen} utmCampaign={utmCampaign} />
      <script type="text/javascript" src="https://koi-3QNGUF4MW0.marketingautomation.services/client/noform.js?ver=1.24" />
      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `var _ss = _ss || []; _ss.push(['_setDomain', 'https://koi-3QNGUF4MW0.marketingautomation.services/net']); _ss.push(['_setAccount', 'KOI-42FV649XZC']); _ss.push(['_trackPageView']); (function() { var ss = document.createElement('script'); ss.type = 'text/javascript'; ss.async = true; ss.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'koi-3QNGUF4MW0.marketingautomation.services/client/ss.js?ver=1.1.1'; var scr = document.getElementsByTagName('script')[0]; scr.parentNode.insertBefore(ss, scr); })();`,
        }}
      />
    </>
  );
}
