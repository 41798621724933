import { Dispatch, SetStateAction } from 'react';
import useOutsideClickRef from '@/hooks/useOutsideClickRef';
import classNames from '@/utils/className';
import Icon from '../Icon';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  children: JSX.Element | JSX.Element[];
  iconOutsideBox?: boolean;
  noPadding?: boolean;
  limitHeight?: boolean;
}

export default function Modal({ isOpen, setIsOpen, children, iconOutsideBox, noPadding, limitHeight }: ModalProps) {
  const variants = {
    ModalContainer: {
      isOpen: {
        true: 'opacity-100 z-[9999]',
        false: 'opacity-0 z-[-1]',
      },
    },
    ModalBox: {
      iconOutsideBox: {
        true: 'p-0 w-[initial]',
        false: '',
      },
      noPadding: {
        true: 'p-0',
        false: '',
      },
      limitHeight: {
        true: 'tablet:h-[initial] tablet:overflow-y-[initial] h-full overflow-y-auto',
        false: '',
      },
    },
    ModalCloseIcon: {
      iconOutsideBox: {
        true: '-top-12 right-0 text-mglGray50',
        false: '',
      },
    },
  } as const;
  const modalRef = useOutsideClickRef({ setIsOpen });

  return (
    <section
      className={classNames(
        'fixed left-0 top-0 flex h-full w-full items-center justify-center overflow-auto bg-black/90 transition-all duration-300 ease-ease [&>svg]:cursor-pointer [&>svg]:text-2xl',
        variants.ModalContainer.isOpen[isOpen.toString()]
      )}
      role="dialog"
      aria-modal={isOpen}>
      <div
        className={classNames(
          'relative h-full max-h-full min-h-[336px] w-full max-w-[760px] overflow-y-auto rounded-none bg-mglGray50 pb-8 pr-0 pt-16 tablet:max-h-[90%] tablet:rounded',
          iconOutsideBox != undefined && variants.ModalBox.iconOutsideBox[iconOutsideBox.toString()],
          noPadding != undefined && variants.ModalBox.noPadding[noPadding.toString()],
          limitHeight != undefined && variants.ModalBox.limitHeight[limitHeight.toString()]
        )}
        ref={modalRef}>
        <div
          className={classNames(
            'absolute right-4 top-4 cursor-pointer text-lg text-black/40',
            iconOutsideBox != undefined && variants.ModalCloseIcon.iconOutsideBox[iconOutsideBox.toString()]
          )}
          onClick={() => setIsOpen(false)}>
          <Icon icon="mdi:close" />
        </div>
        {children}
      </div>
    </section>
  );
}
